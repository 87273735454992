import { AxiosResponse } from 'axios'
import { http } from '@/core/classes/HTTP.class'

import {
    FTLBidResponseT,
    FTLBidsListResponseT,
    FTLDangerClassesResponseT,
    FTLLoadCapacityResponseT,
    FTLLoadTransportTypesResponseT,
    FTLPrintTTNResponseT,
    FTLPrintWaybillResponseT,
} from '../types/Responses/FTL.responses'

import {
    FTLBidsDownloadExcelResponseT,
    FTLBidsQueryFilters,
    FTLExcelParams,
} from '../types/History.types'

const GATEWAY_URL = process.env.VUE_APP_GATEWAY_URL

export class FTLRepository {
    getLoadCapacity(): Promise<AxiosResponse<FTLLoadCapacityResponseT>> {
        return http.get('/v2/ftl/cubature')
    }

    getTransportTypes(): Promise<AxiosResponse<FTLLoadTransportTypesResponseT>> {
        return http.get('/ftl/transport-types')
    }

    createBID(payload: FormData): Promise<AxiosResponse<void>> {
        return http.postFormData('/v2/ftl-bid', payload)
    }

    getDangerClasses(): Promise<AxiosResponse<FTLDangerClassesResponseT>> {
        return http.get('/danger-classes')
    }

    getBids(params: FTLBidsQueryFilters): Promise<AxiosResponse<FTLBidsListResponseT>> {
        return http.get('/v2/ftl-bid', { params })
    }

    getBid(bid_id: number): Promise<AxiosResponse<FTLBidResponseT>> {
        return http.get(`/v2/ftl-bid/${bid_id}`)
    }

    printTTN(id: number): Promise<AxiosResponse<FTLPrintTTNResponseT>> {
        return http.get(`/v2/ftl/pdf/${id}`)
    }

    printWaybill(id: number): Promise<AxiosResponse<FTLPrintWaybillResponseT>> {
        return http.get(`/v2/ftl/waybill/pdf/${id}`)
    }

    printRoute(order_id: number): Promise<AxiosResponse<any>> {
        return http.get(`/ftl-orders/${order_id}/printout`, { responseType: 'blob' })
    }

    exportExcelFTLBids(
        params: FTLExcelParams
    ): Promise<AxiosResponse<FTLBidsDownloadExcelResponseT>> {
        return http.get('/v2/ftl-bid/export', {
            responseType: 'blob',
            params: { ...params },
        })
    }

    printFtlOrderInvoices(order_id: number): Promise<AxiosResponse<any>> {
        return http.get(`${GATEWAY_URL}/print/api/ftl-order/${order_id}`, { responseType: 'blob' })
    }

    printFtlOrderStockDeliveryNoteInvoice(order_id: number, stock_delivery_note_index: number): Promise<AxiosResponse<any>> {
        return http.get(`${GATEWAY_URL}/print/api/ftl-order/${order_id}/${stock_delivery_note_index}`, { responseType: 'blob' })
    }

    printFtlBidInvoices(bid_id: number): Promise<AxiosResponse<any>> {
        return http.get(`${GATEWAY_URL}/print/api/ftl-bid/${bid_id}`, { responseType: 'blob' })
    }

    printFtlBidWaypointInvoice(bid_id: number, waypoint_index: number): Promise<AxiosResponse<any>> {
        return http.get(`${GATEWAY_URL}/print/api/ftl-bid/${bid_id}/${waypoint_index}`, { responseType: 'blob' })
    }
}
