import { FTLRepository } from '../repositories/FTL.repository'
import {
    FTLBidsDownloadExcelResponseT,
    FTLBidsQueryFilters,
    FTLExcelParams,
} from '../types/History.types'
import {
    FTLBidResponseT,
    FTLBidsListResponseT,
    FTLDangerClassesResponseT,
    FTLLoadCapacityResponseT,
    FTLLoadTransportTypesResponseT,
    FTLPrintTTNResponseT,
    FTLPrintWaybillResponseT,
} from '../types/Responses/FTL.responses'

const ftlRepository = new FTLRepository()

const ftlService = {
    async getLoadCapacity(): Promise<FTLLoadCapacityResponseT> {
        const response = await ftlRepository.getLoadCapacity()
        return response.data
    },

    async getTransportTypes(): Promise<FTLLoadTransportTypesResponseT> {
        const response = await ftlRepository.getTransportTypes()
        return response.data
    },

    async createBID(payload: FormData): Promise<void> {
        const response = await ftlRepository.createBID(payload)
        return response.data
    },

    async getDangerClasses(): Promise<FTLDangerClassesResponseT> {
        const response = await ftlRepository.getDangerClasses()
        return response.data
    },

    async getBids(params: FTLBidsQueryFilters): Promise<FTLBidsListResponseT> {
        const response = await ftlRepository.getBids(params)
        return response.data
    },

    async getBid(bid_id: number): Promise<FTLBidResponseT> {
        const response = await ftlRepository.getBid(bid_id)
        return response.data
    },

    async printTTN(id: number): Promise<FTLPrintTTNResponseT> {
        const response = await ftlRepository.printTTN(id)
        return response.data
    },

    async printWaybill(id: number): Promise<FTLPrintWaybillResponseT> {
        const response = await ftlRepository.printWaybill(id)
        return response.data
    },

    async printRoute(order_id: number): Promise<any> {
        const response = await ftlRepository.printRoute(order_id)
        return response.data
    },

    async exportExcelFTLBids(params: FTLExcelParams): Promise<FTLBidsDownloadExcelResponseT> {
        const response = await ftlRepository.exportExcelFTLBids(params)
        return response.data
    },

    async printFtlOrderInvoices(order_id: number): Promise<any> {
        const response = await ftlRepository.printFtlOrderInvoices(order_id)
        return response.data
    },

    async printFtlOrderStockDeliveryNoteInvoice(order_id: number, stock_delivery_note_index: number): Promise<any> {
        const response = await ftlRepository.printFtlOrderStockDeliveryNoteInvoice(order_id, stock_delivery_note_index)
        return response.data
    },

    async printFtlBidInvoices(bid_id: number): Promise<any> {
        const response = await ftlRepository.printFtlBidInvoices(bid_id)
        return response.data
    },

    async printFtlBidWaypointInvoice(bid_id: number, waypoint_index: number): Promise<any> {
        const response = await ftlRepository.printFtlBidWaypointInvoice(bid_id, waypoint_index)
        return response.data
    }
}

export default ftlService
