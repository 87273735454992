import { FTLI } from '../interfaces/FTL.interface'
import ftlService from '../services/FTL.service'
import {
    FTLBidsDownloadExcelResponseT,
    FTLBidsQueryFilters,
    FTLExcelParams,
} from '../types/History.types'
import {
    FTLBidResponseT,
    FTLBidsListResponseT,
    FTLDangerClassesResponseT,
    FTLLoadCapacityResponseT,
    FTLLoadTransportTypesResponseT,
    FTLPrintTTNResponseT,
    FTLPrintWaybillResponseT,
} from '../types/Responses/FTL.responses'

export class FTLRealisation implements FTLI {
    async getLoadCapacity(): Promise<FTLLoadCapacityResponseT> {
        return await ftlService.getLoadCapacity()
    }

    async getTransportTypes(): Promise<FTLLoadTransportTypesResponseT> {
        return await ftlService.getTransportTypes()
    }

    async createBID(payload: FormData): Promise<void> {
        return await ftlService.createBID(payload)
    }

    async getBids(params: FTLBidsQueryFilters): Promise<FTLBidsListResponseT> {
        return await ftlService.getBids(params)
    }

    async getDangerClasses(): Promise<FTLDangerClassesResponseT> {
        return await ftlService.getDangerClasses()
    }

    async getBid(bid_id: number): Promise<FTLBidResponseT> {
        return await ftlService.getBid(bid_id)
    }

    async printTTN(id: number): Promise<FTLPrintTTNResponseT> {
        return await ftlService.printTTN(id)
    }

    async printWaybill(id: number): Promise<FTLPrintWaybillResponseT> {
        return await ftlService.printWaybill(id)
    }

    async printRoute(order_id: number): Promise<any> {
        return await ftlService.printRoute(order_id)
    }

    exportExcelFTLBids(params: FTLExcelParams): Promise<FTLBidsDownloadExcelResponseT> {
        return ftlService.exportExcelFTLBids(params)
    }

    printFtlOrderInvoices(order_id: number): Promise<any> {
        return ftlService.printFtlOrderInvoices(order_id)
    }

    printFtlOrderStockDeliveryNoteInvoice(order_id: number, stock_delivery_note_index: number): Promise<any> {
        return ftlService.printFtlOrderStockDeliveryNoteInvoice(order_id, stock_delivery_note_index)
    }

    printFtlBidInvoices(bid_id: number): Promise<any> {
        return ftlService.printFtlBidInvoices(bid_id)
    }

    printFtlBidWaypointInvoice(bid_id: number, waypoint_index: number): Promise<any> {
        return ftlService.printFtlBidWaypointInvoice(bid_id, waypoint_index)
    }
}
